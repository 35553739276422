@font-face {
  font-family: GraphikRegular ;
  src: url(./Graphik-Font-Family/GraphikRegular.otf);
}
@font-face {
  font-family: GraphikMedium ;
  src: url(./Graphik-Font-Family/GraphikMedium.otf);
}
@font-face {
  font-family: GraphikSemibold ;
  src: url(./Graphik-Font-Family/GraphikSemibold.otf);
}
@font-face {
  font-family: SkBold;
  src: url(./Sk-Modernist/Sk-Modernist-Bold.otf);
}

@font-face {
  font-family: Skregular;
  src: url(./Sk-Modernist/Sk-Modernist-Regular.otf);
}

html {
  scroll-behavior: smooth;
}

.regular{
  font-family: Skregular ;

}
.medium{
  font-family: GraphikMedium ;
}
.semibold{
  font-family: GraphikSemibold ;
}
.bold{
  font-family: SkBold ;
}

/* For WebKit browsers (Chrome, Safari, Edge) */
::-webkit-scrollbar {
  width: 2px; 
}

::-webkit-scrollbar-thumb {
  background-color: black; 
  width: 2px;
  /* border-radius: 10px;  */
}

::-webkit-scrollbar-track {
  background: transparent;
}

/* For Firefox */
* {
  scrollbar-width: thin; 
  scrollbar-color: black transparent; 
}

/* Make the Lottie container fixed and fullscreen */
.lottie-container {
  width: 100vw; 
  height: 100vh; 
  display: flex;
  overflow: hidden;
  position: fixed;
  justify-content: center; 
  align-items: center; 
  background-color: transparent;
  /* top: 0;
  left: 0; */
  z-index: 2; /* Make sure it's behind the content */
}

.lottie-container svg {
  width: 100%; 
  height: 100%;
  object-fit: cover; 
}
/*  */

.background-video {
  position: absolute;
  mix-blend-mode: normal;
  background-color: rgba(0, 0, 0, 0.1);
        filter: brightness(80%);
  top: 0;
  left: 0;
  width: 100%;
  height: 101%;
  object-fit: cover; /* Ensures the video covers the entire area */
  z-index: 3; /* Ensures the video stays behind the Lottie animation */
}

/* Content sections for scrolling */
.content-section {
  min-height: 100vh;
  padding: 20px;
  display: flex;
  /* justify-content: center;
  align-items: center; */
  /* text-align: center; */
  background-color: transparent;
  /* margin-bottom: 10px; */
  z-index: 1; /* Make sure it's behind the content */

}

/* .content-section:nth-child(even) {
  background-color: #eaeaea;
} */


/* Add more styles as necessary */

h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

p {
  font-size: 1.5rem;
  max-width: 800px;
  line-height: 1.6;
}

.slide-in-bottom-d {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.4s both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.4s both;
}

.slide-in-bottom-e {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.7s both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) 0.7s both;
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(15px);
            transform: translateY(15px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}

.scale-in-bl {
	-webkit-animation: scale-in-bl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: scale-in-bl 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}


 @-webkit-keyframes scale-in-bl {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}
@keyframes scale-in-bl {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: 0% 100%;
            transform-origin: 0% 100%;
    opacity: 1;
  }
}
