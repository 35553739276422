.header{
    z-index: 99;
    filter: invert();
    mix-blend-mode: difference;
    position: fixed;
    inset: 0% 0% auto;
    transform-style: preserve-3d;
    transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
}

.navbar-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    transition: transform 0.3s ease;
  }
  
  .navbar-hidden {
    transform: translateY(-100%); /* Moves the navbar out of view */
  }
  
  .navbar-visible {
    transform: translateY(0); /* Navbar in its normal position */
  }
  .slide-in-top {
	-webkit-animation: slide-in-top .75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-top .75s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

.slide-out-top {
	-webkit-animation: slide-out-top .75s cubic-bezier(0.550, 0.085, 0.680, 0.530) .3s both;
	        animation: slide-out-top .75s cubic-bezier(0.550, 0.085, 0.680, 0.530) .3s both;
}


 @-webkit-keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
  }
  @keyframes slide-out-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
  }
  
 @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-1000px);
              transform: translateY(-1000px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      opacity: 1;
    }
  }
  
  .slide-bck-top {
	-webkit-animation: slide-bck-top 0.65s ease-out 1s both;
	        animation: slide-bck-top 0.65s ease-out 1s both;
            transition: all;
}

 @-webkit-keyframes slide-bck-top {
    0% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
    }
    100% {
      -webkit-transform: translateZ(-400px) translateY(-200px);
              transform: translateZ(-400px) translateY(-200px);
              scale: .75;
    }
  }
  @keyframes slide-bck-top {
    0% {
      -webkit-transform: translateZ(0) translateY(0);
              transform: translateZ(0) translateY(0);
    }
    100% {
      -webkit-transform: translateZ(-400px) translateY(-200px);
              transform: translateZ(-400px) translateY(-200px);
              scale: .75;

    }
  }
  
 
  
  .wrapper {
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-align: center;
    margin: 0; 
    padding: 0; 
  }
  
  .first-text {
    animation: showup 7s forwards;
    display: inline-block; 
  }
  
  .second-text {
    width: 0px;
    animation: reveal 7s forwards;
    display: inline-block;
  }
  
  .second-text span {
    margin-left: -600px;
    animation: slidein 7s forwards;
    display: inline-block;
    margin: 0; 
    padding: 0;
  }
  
  @keyframes showup {
    0% { opacity: 0; }
    20% { opacity: 1; }
    80% { opacity: 1; }
    100% { opacity: 0; }
  }
  
  @keyframes slidein {
    0% { margin-left: -600px; }
    20% { margin-left: -600px; }
    35% { margin-left: 0px; }
    100% { margin-left: 0px; }
  }
  
  @keyframes reveal {
    0% { opacity: 0; width: 0px; }
    20% { opacity: 1; width: 0px; }
    30% { width: 600px; }
    80% { opacity: 1; }
    100% { opacity: 0; width: 600px; }
  }
  
  
  

  /* p {
    font-size: 12px;
    color: #999;
    margin-top: 200px;
  } */
  
  .flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  
  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%); /* Moves left by half to create a continuous loop */
    }
  }
  
  .animate-marquee {
    display: flex;
    width: 200%; /* Extend container width to fit two sets of skills */
    animation: marquee 20s linear infinite; /* Infinite scrolling animation */
    white-space: nowrap;
  }
  